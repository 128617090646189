import React from 'react';

import './App.css';
import {
  HashRouter,
  Route,
  Routes
} from "react-router-dom";
import MainPage from './Pages/MainPage';
import ProjPage from './Pages/ProjPage';
import VoicePage from './Pages/PDFVoiceTranslate';
import ShowLoader from './Pages/ShowLoader';
import CatJump from './Pages/CatJump';
import RevolvingRagdoll from './Pages/RevolvingRagdoll';
import {Navigation} from './Components/Navigation/navigation';

function App() {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
        <Navigation />
        <div style={{paddingTop:'80px'}}>
          <Routes>
            <Route exact path="/" element={<MainPage/>} />
            <Route path="/project" element={<ProjPage/>} />
            <Route path="/catjump" element={<CatJump/>} />
            <Route path="/pdfvoicetranslator" element={<VoicePage/>} />
            <Route path="/showloader" element={<ShowLoader/>} />
            <Route path="/revolvingragdoll" element={<RevolvingRagdoll/>} />
          </Routes>
        </div>
    </HashRouter>
  );
}

export default App;
