import { Navigation } from '../Components/Navigation/navigation';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ProjPage.css';
import React from "react";

import pic1 from './Website/PDFVoiceTranslate/voice3.png';
import pic2 from './Website/PDFVoiceTranslate/voice2.png';

const VoicePage = () => {

  return (
    <div className="App">
      <div>
        <div className="mainContent">
          <h1 className="projectName">PDFVoiceTranslate</h1>
          <div className="infoPage">
            <Carousel className="slider">
            <div>
                <img src={pic1} />
              </div>
              <div>
                <img src={pic2} />
              </div>
            </Carousel>
            <div style={{paddingLeft:"10%",paddingRight:"10%", textAlign: "left"}}>
            <h2>About</h2>
            <p>Designed and implemented a website hosted on Vercel for processing PDF text passages, enabling highlighting and text‑to‑speech functionality in the user’s preferred language using Google Cloud Platform APIs.
            </p>
            <br></br>
            <br></br>
            <h2>Technologies used:</h2>
            <ul>
            <li>Google Cloud Platform APIs for translation and text‑to‑speech services</li>
            <li>React</li>
            <li>TypeScript</li>
            <li>Express.js</li>
            <li>Node.js.js</li>
            </ul>
            <a className="link" target="_blank" href="https://www.pdfvoicetranslate.xyz">Link to website</a>
          </div>
          
          </div>
        </div>

      </div>
    </div>
  );
};

export default VoicePage;