import { Navigation } from '../Components/Navigation/navigation';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ProjPage.css';
import React from "react";

import pic1 from './Website/ShowLoader/loader1.png';

const VoicePage = () => {

  return (
    <div className="App">
      <div>
        <div className="mainContent">
          <h1 className="projectName">Show Loader</h1>
          <div className="infoPage">
            <Carousel className="slider">
              <div>
                <img src={pic1} />
              </div>
            </Carousel>
            <div style={{paddingLeft:"10%",paddingRight:"10%", textAlign: "left"}}>
            <h2>About</h2>
            <p>This is a desktop application built using Python and Tkinter. The application monitors specific websites and displays the details of the newly released show, including the title and episode.
            </p>
            <br></br>
            <br></br>  
            <h2>Technologies used:</h2>
            <ul>
            <li>Python</li>
            <li>Tkinter</li>
            <li>Requests</li>
            <li>Beautiful soup</li>
            </ul>
          </div>
          
          </div>
        </div>

      </div>
    </div>
  );
};

export default VoicePage;