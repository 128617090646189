import { Navigation } from '../Components/Navigation/navigation';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ProjPage.css';
import React from "react";

import pic1 from './Website/Revolving Rabbits/rabbit1.png';
import pic2 from './Website/Revolving Rabbits/rabbit2.png';
import pic3 from './Website/Revolving Rabbits/rabbit3.png';
import pic4 from './Website/Revolving Rabbits/rabbit4.png';

const RevolvingRagdoll = () => {

  return (
    <div className="App">
      <div>
        <div className="mainContent">
          <h1 className="projectName">Revolving ragdoll</h1>
          <div className="infoPage">
            <Carousel className="slider">
              <div>
                <img src={pic1} />
              </div>
              <div>
                <img src={pic2} />
              </div>
              <div>
                <img src={pic3} />
              </div>
              <div>
                <img src={pic4} />
              </div>
            </Carousel>
            <div style={{paddingLeft:"10%",paddingRight:"10%", textAlign: "left"}}>
            <h2>About</h2>
            <p>
            Revolving ragdoll was made with the unity game engine, the goal of the game is to save the rabbits on the platform by rotating the platform to dodge incoming cannonballs and stop them from dropping into the abyss below.
            </p>
            <br></br>
            <br></br>  
            <h2>Technologies used:</h2>
            <ul>
            <li>C# Programming language</li>
            <li>Unity Game engine</li>
            </ul>
          </div>
          
          </div>
        </div>

      </div>
    </div>
  );
};

export default RevolvingRagdoll;